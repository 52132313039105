.wrapper {
  @apply flex flex-col justify-center items-center text-center bg-summer-green-50;
  @apply mb-24 !important;
}

.content {
  @apply pt-12 min-h-[200px];

  & :global(.tag) {
    @apply text-xs font-bold font-sans tracking-wider uppercase block mb-1;
  }

  & :global(h1) {
    @apply pb-6 text-3xl lg:text-5xl md:text-4xl;
  }

  & :global(.description) {
    @apply px-8 mx-auto text-xl max-w-1xl mb-22;

    & :global(.landing-page-link) {
      @apply inline-block pt-4 relative;
      & a {
        @apply underline;
      }
    }
  }
}

.form {
  @apply box-border flex flex-wrap m-4 mx-auto text-left bg-white p-7 relative;
  @apply shadow-sm text-black-100 p-5 lg:p-7.5 -mb-14 z-10 -mt-13;
  @apply max-w-8xl;
  width: calc(100% - 2rem);

  @screen md {
    width: calc(100% - 4rem);
  }
}
